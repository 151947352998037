<template>
  <mobile-screen
    :header="true"
    screen-class="edit-form-screen edit-company icon-app1"
  >
    <template v-slot:header>
      <top-header-menu-wrapper menu-class="icon-hea1">
        <template v-slot:left>
          <router-link :to="{ name: 'r_manage-companies' }">
            <icon icon="#cx-hea1-arrow-left"></icon>
          </router-link>
        </template>
        <div class="component-title">
          Edit company
        </div>
        <template v-slot:right>
          <button @click="initSubmit">
            <icon icon="#cx-hea1-save" />
          </button>
        </template>
      </top-header-menu-wrapper>
    </template>
    <template v-if="listsLoaded">
      <VeeForm
        @submit="submitForm"
        ref="editCompaniesForm"
        v-slot="{ errors }"
        class="form"
        :initial-values="this.selectedCompany && this.selectedCompany[0]"
        novalidate
      >
        <ul class="clebex-item-section">
          <li
            class="clebex-item-section-item full-right-underline"
            :class="{ 'has-error': errors.name }"
          >
            <span class="error-message" v-if="errors.name">
              {{ errors.name }}
            </span>
            <div class="clebex-item-content-wrapper">
              <div class="clebex-section-input">
                <label for="name" class="clebex-section-input-label">
                  Name
                </label>
                <Field
                  name="name"
                  as="input"
                  type="text"
                  rules="required"
                  id="name"
                  :disabled="submitting"
                />
              </div>
            </div>
          </li>
          <li
            class="clebex-item-section-item full-right-underline"
            :class="{ 'has-error': errors.subdomain }"
          >
            <span class="error-message" v-if="errors.subdomain">
              {{ errors.subdomain }}
            </span>
            <div class="clebex-item-content-wrapper">
              <div class="clebex-section-input">
                <label for="subdomain" class="clebex-section-input-label">
                  Subdomain
                </label>
                <Field
                  name="subdomain"
                  as="input"
                  type="text"
                  rules="required"
                  id="subdomain"
                  :disabled="submitting"
                />
              </div>
            </div>
          </li>
          <li class="clebex-item-section-item full-right-underline">
            <div class="clebex-item-content-wrapper">
              <div class="clebex-section-input">
                <label for="emailDomainId" class="clebex-section-input-label">
                  Email domain
                </label>
                <Field
                  name="email_domain"
                  as="input"
                  type="text"
                  id="emailDomainId"
                  :disabled="submitting"
                />
              </div>
            </div>
          </li>
          <li class="clebex-item-section-item full-right-underline">
            <div class="clebex-item-content-wrapper">
              <div class="clebex-section-input">
                <label for="companyTypeId" class="clebex-section-input-label">
                  Company type
                </label>
                <p v-if="currentCompanyType" class="text-label ellipsis">
                  <span>{{ currentCompanyType }}</span>
                </p>
                <Field
                  name="company_type_id"
                  as="input"
                  type="hidden"
                  rules="required"
                  id="companyTypeId"
                  :disabled="submitting"
                  v-model="companyTypeId"
                >
                </Field>
              </div>
            </div>
          </li>
        </ul>
        <div
          v-if="selectedCompany && selectedCompany.length"
          class="clebex-item-section"
        >
          <router-link
            :to="{ name: 'r_edit-company-select-country' }"
            class="clebex-item-section-item full-right-underline"
            :class="{ 'has-error': errors.country_id }"
          >
            <span class="error-message" v-if="errors.country_id">
              {{ errors.country_id }}
            </span>
            <div class="clebex-item-content-wrapper">
              <div class="clebex-section-input">
                <label
                  for="countryId"
                  class="clebex-section-input-label cursor-pointer"
                >
                  Country
                </label>
                <p
                  v-if="selectedCompany[0].country_id"
                  class="text-label ellipsis"
                >
                  <template v-if="selectedCountry">
                    {{ selectedCountry.name }}
                  </template>
                  <template v-else>
                    {{ displayCountryName(selectedCompany[0].country_id) }}
                  </template>
                </p>
                <Field
                  name="country_id"
                  as="input"
                  type="hidden"
                  rules="required"
                  id="countryId"
                  :disabled="submitting"
                  v-model="countryId"
                >
                </Field>
              </div>
              <span class="follow-up-icons">
                <span class="follow-up-icon-item">
                  <icon
                    icon="#cx-app1-arrow-right-12x12"
                    width="12"
                    height="12"
                  ></icon>
                </span>
              </span>
            </div>
          </router-link>
          <router-link
            :to="{ name: 'r_edit-company-select-language' }"
            class="clebex-item-section-item full-right-underline cursor-pointer"
            :class="{ 'has-error': errors.language_id }"
          >
            <span class="error-message" v-if="errors.language_id">
              {{ errors.language_id }}
            </span>
            <div class="clebex-item-content-wrapper">
              <div class="clebex-section-input">
                <label
                  for="languageId"
                  class="clebex-section-input-label cursor-pointer"
                >
                  Language
                </label>
                <p
                  v-if="selectedCompany[0].language_id"
                  class="text-label ellipsis"
                >
                  <template v-if="selectedLanguage">
                    {{ displayLanguage(selectedLanguage.id) }}
                  </template>
                  <template v-else>
                    {{ displayLanguage(selectedCompany[0].language_id) }}
                  </template>
                </p>
                <Field
                  name="language_id"
                  as="input"
                  type="hidden"
                  rules="required"
                  id="languageId"
                  :disabled="submitting"
                  v-model="languageId"
                >
                </Field>
              </div>
              <span class="follow-up-icons">
                <span class="follow-up-icon-item">
                  <icon
                    icon="#cx-app1-arrow-right-12x12"
                    width="12"
                    height="12"
                  ></icon>
                </span>
              </span>
            </div>
          </router-link>
          <router-link
            :to="{ name: 'r_edit-company-select-timezone' }"
            class="clebex-item-section-item full-right-underline cursor-pointer"
            :class="{ 'has-error': errors.timezone_id }"
          >
            <span class="error-message" v-if="errors.timezone_id">
              {{ errors.timezone_id }}
            </span>
            <div class="clebex-item-content-wrapper">
              <div class="clebex-section-input">
                <label for="timezoneId" class="clebex-section-input-label">
                  Timezone
                </label>
                <p
                  v-if="selectedCompany[0].timezone_id"
                  class="text-label ellipsis"
                >
                  <template v-if="selectedTimezone">
                    {{ displayTimezone(selectedTimezone) }}
                  </template>
                  <template v-else>
                    {{ displayTimezone(selectedCompany[0].timezone_id) }}
                  </template>
                </p>
                <Field
                  name="timezone_id"
                  as="input"
                  type="hidden"
                  rules="required"
                  id="timezoneId"
                  :disabled="submitting"
                  v-model="timezoneId"
                >
                </Field>
              </div>
              <span class="follow-up-icons">
                <span class="follow-up-icon-item">
                  <icon
                    icon="#cx-app1-arrow-right-12x12"
                    width="12"
                    height="12"
                  ></icon>
                </span>
              </span>
            </div>
          </router-link>
          <router-link
            :to="{ name: 'r_edit-company-select-data-region' }"
            class="clebex-item-section-item full-right-underline cursor-pointer"
            :class="{ 'has-error': errors.data_region_id }"
          >
            <span class="error-message" v-if="errors.data_region_id">
              {{ errors.data_region_id }}
            </span>
            <div class="clebex-item-content-wrapper">
              <div class="clebex-section-input">
                <label for="dataRegionId" class="clebex-section-input-label">
                  Data region
                </label>
                <p class="text-label ellipsis">
                  <template v-if="selectedDataRegion">
                    {{ displayDataRegion(selectedDataRegion.id) }}
                  </template>
                  <template v-else-if="selectedCompany[0].data_region_id">
                    {{ displayDataRegion(selectedCompany[0].data_region_id) }}
                  </template>
                </p>
                <Field
                  name="data_region_id"
                  as="input"
                  type="hidden"
                  rules="required"
                  id="dataRegionId"
                  :disabled="submitting"
                  v-model="dataRegionId"
                >
                </Field>
              </div>
              <span class="follow-up-icons">
                <span class="follow-up-icon-item">
                  <icon
                    icon="#cx-app1-arrow-right-12x12"
                    width="12"
                    height="12"
                  ></icon>
                </span>
              </span>
            </div>
          </router-link>
        </div>
        <button type="submit" style="display: none;" ref="submitFormBtn">
          Submit form
        </button>
      </VeeForm>
    </template>
  </mobile-screen>
  <router-view />
</template>

<script>
import httpServiceAuth from "@/services/http-service";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import MobileScreen from "@/layouts/MobileScreen";
import { apiEndpoints } from "@/services/constants";
import { mapActions, mapState } from "vuex";
import { errorHandler } from "@/services/error-handler";
import countriesMixin from "@/services/mixins/countries/countries-mixin";
import languagesMixin from "@/services/mixins/languages/languages-mixin";
import timezonesMixin from "@/services/mixins/timezones/timezones-mixin";
import dataRegionsMixin from "@/services/mixins/data_region/data-region";

export default {
  name: "EditCompany",
  data() {
    return {
      submitting: false
    };
  },
  created() {
    this.setSelectedCountry(null);
    this.setSelectedLanguage(null);
    this.setCompanyType(null);
    this.setSelectedDataRegion(null);
  },
  mounted() {
    if (!this.selectedCompany) {
      this.$router.push({ name: "r_manage-companies" });
    } else {
      this.setSelectedTimezone(this.selectedCompany[0].timezone_id);
    }
  },
  watch: {
    selectedCompany() {
      if (this.selectedCompany) {
        this.selectedCompany.length &&
          this.$refs.editCompaniesForm.setValues({
            ...this.selectedCompany[0]
          });
      } else {
        this.$refs.editCompaniesForm.setValues({
          name: "",
          subdomain: "",
          company_type_id: "",
          country_id: "",
          timezone_id: "",
          language_id: ""
        });
        this.setSelectedCompany(null);
      }
    }
  },
  computed: {
    ...mapState("companies", [
      "selectedCompany",
      "companies",
      "companyTypes",
      "selectedCompanyType"
    ]),
    listsLoaded() {
      return !!(
        this.companyTypes &&
        this.companyTypes.data &&
        this.companyTypes.data.length &&
        this.languages &&
        this.languages.data &&
        this.languages.data.length &&
        this.countries &&
        this.countries.data &&
        this.countries.data.length &&
        this.timezones &&
        this.timezones.data &&
        this.timezones.data.length &&
        this.dataRegions &&
        this.dataRegions.data &&
        this.dataRegions.data.length
      );
    },
    countryId() {
      if (this.selectedCountry) {
        return this.selectedCountry.id;
      } else if (this.selectedCompany && this.selectedCompany.length) {
        return this.selectedCompany[0].country_id;
      }
      return null;
    },
    languageId() {
      if (this.selectedLanguage) {
        return this.selectedLanguage.id;
      } else if (this.selectedCompany && this.selectedCompany.length) {
        return this.selectedCompany[0].language_id;
      }
      return null;
    },
    timezoneId() {
      if (this.selectedTimezone) {
        return this.selectedTimezone;
      } else if (this.selectedCompany && this.selectedCompany.length) {
        return this.selectedCompany[0].timezone_id;
      }
      return null;
    },
    dataRegionId() {
      if (this.selectedDataRegion) {
        return this.selectedDataRegion.id;
      } else if (this.selectedCompany && this.selectedCompany.length) {
        return this.selectedCompany[0].data_region_id;
      }
      return null;
    },
    companyTypeId() {
      if (
        this.selectedCompany &&
        this.selectedCompany[0] &&
        this.selectedCompany[0].company_type_id
      ) {
        return this.selectedCompany[0].company_type_id;
      }
      return null;
    },
    currentCompanyType() {
      if (
        this.selectedCompany &&
        this.selectedCompany[0] &&
        this.companyTypes &&
        this.companyTypes.data &&
        this.companyTypes.data.length
      ) {
        const filtered = this.companyTypes.data.filter(
          type => type.id === this.selectedCompany[0].company_type_id
        );
        if (filtered && filtered.length) {
          return filtered[0].description;
        }
        return null;
      }
      return null;
    }
  },
  methods: {
    ...mapActions("companies", [
      "getCompanies",
      "setSelectedCompany",
      "setCompanyType"
    ]),
    ...mapActions("country", ["setSelectedCountry"]),
    ...mapActions("timezone", ["setSelectedTimezone"]),
    ...mapActions("language", ["setSelectedLanguage"]),
    ...mapActions("dataRegion", ["setSelectedDataRegion"]),
    submitForm(values, { resetForm }) {
      if (values) {
        const data = {
          ...this.selectedCompany[0],
          ...values
        };

        this.submitting = true;
        this.submitMasterData(data, resetForm);
      }
    },
    submitMasterData(values, resetForm) {
      this.$store.commit("loader/setScreenLoading", true, { root: true });
      httpServiceAuth
        .put(
          `${apiEndpoints.master.companies}/${this.selectedCompany[0].id}`,
          values
        )
        .then(() => {
          resetForm();
          this.getCompanies();
          this.setSelectedCompany([
            {
              ...values
            }
          ]);
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response, this.findElement());
          }
        })
        .finally(() => {
          this.submitting = false;
          this.$store.commit("loader/setScreenLoading", false, { root: true });
        });
    },
    initSubmit() {
      const { submitFormBtn } = this.$refs;
      submitFormBtn.click();
    }
  },
  components: {
    MobileScreen,
    TopHeaderMenuWrapper
  },
  mixins: [countriesMixin, languagesMixin, timezonesMixin, dataRegionsMixin]
};
</script>
